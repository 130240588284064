import React, { useContext, useEffect, useState } from "react";
import Logo from "./logo";
import Cookies from "js-cookie";
import { TfiPowerOff } from "react-icons/tfi";
import axios from "axios";
import ExportDataHandler from "./ExportDataHandler";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AppContext } from "../context/appContext";
import { useLocation, useNavigate } from "react-router-dom";

const Header = () => {
  const [data, setData] = useState();
  const token = Cookies.get("token");

  useEffect(() => {
    fetchData();
  }, [token]);

  function fetchData() {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://servicesapi.maplestore.in/user/repairdatas",
        headers: {
          Authorization: token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data.repairs));
          if (response.data.success === true) {
            setData(response.data.repairs);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const LogOutHandler = () => {
    Cookies.remove("token");
    window.location.reload();
  };

  const LogOut = () => {
    return (
      <button
        className="rounded md:rounded-lg md:text-xl md:border border-orange-500 md:px-2  p-2 text-white "
        type="submit"
        onClick={LogOutHandler}
      >
        <span className=" text-orange-500">
          <TfiPowerOff />
        </span>
        {/* <span className="">logout</span> */}
      </button>
    );
  };

  const NavButton = ({ title, onClick, locationName }) => {
    return (
      <div
        className={`md:flex cursor-pointer   items-center justify-evenly rounded-lg md:text-sm px-5 md:px-3 lg:px-5 py-1 border border-orange-500 ${
          locationName === location.pathname
            ? "bg-orange-500/95 text-white"
            : "text-orange-500"
        }`}
        onClick={onClick}
      >
        {title}
      </div>
    );
  };

  const navigate = useNavigate();
  const location = useLocation();

  const { openMenu, setOpenMenu } = useContext(AppContext);

  return (
    <>
      <div className=" flex justify-between  items-center px-3 sm:px-10 py-3 gap-2 bg-orange-100 border-b border-orange-300 relative">
        <Logo color1="black" />

        <div
          className={`md:flex  md:flex-row flex flex-col justify-between md:border-none md:w-auto w-[100%] sm:w-[50%] left-0 sm:left-auto sm:right-0   gap-3  md:static absolute top-[100%]  z-10  md:p-0 p-5 ${
            !openMenu ? "hidden" : "bg-orange-100 border-t border-orange-400"
          } `}
        >
          <NavButton
            title="Dashboard"
            locationName="/"
            onClick={() => {
              navigate("/");
              setOpenMenu(false);
            }}
          />
          <NavButton
            title="Services"
            locationName="/services"
            onClick={() => {
              navigate("/services");
              setOpenMenu(false);
            }}
          />
          <NavButton
            title="Buy Pack"
            locationName="/buypack"
            onClick={() => {
              navigate("/buypack");
              setOpenMenu(false);
            }}
          />
          <NavButton
            title="Woo Commerce "
            locationName="/woocommerce"
            onClick={() => {
              navigate("/woocommerce");
              setOpenMenu(false);
            }}
          />
          <NavButton
            title="Abandoned Checkout"
            locationName="/checkout"
            onClick={() => {
              navigate("/checkout");
              setOpenMenu(false);
            }}
          />
          <div className="flex justify-center  mt-10 md:hidden">
            <span className=" border border-orange-500 rounded-md flex justify-center items-center pr-4 text-orange-500">
              <LogOut /> Logout
            </span>
          </div>
        </div>

        <div className=" gap-5 flex-row  hidden md:block">
          <LogOut />
        </div>

        <div
          className="border-white h-full w-7 md:hidden rounded-lg flex flex-col gap-2 justify-evenly   cursor-pointer"
          onClick={() => setOpenMenu(!openMenu)}
        >
          <div className="border-b border-[.1rem] border-orange-400"></div>
          <div className="border-b border-[.1rem] border-orange-400"></div>
          <div className=" border-b border-[.1rem] border-orange-400"></div>
        </div>
      </div>
      <ToastContainer position="top-center" />
    </>
  );
};

export default Header;
