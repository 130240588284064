import React from "react";
import Filter from "./filter";

const MiniHeader = ({
  title,
  onExport,
  date,
  data,
  setDate,
  setData,
  axiosUrl,
  res_Array,
}) => {
  return (
    <div className=" w-full  ">
      <div className="w-full text-center mt-1 md:mt-10 flex flex-col items-center gap-5">
        <div className=" flex gap-5 w-9/12 justify-between items-center border-y py-2 px-5 md:pb-6 xl:pb-5 ">
          <h1 className="font-bold text-xs md:text-lg uppercase text-orange-500">
            {title}
          </h1>

          <button
            className="py-2 px-5 bg-black text-white font-semibold rounded md:rounded-md md:text-sm text-xs "
            onClick={() => onExport()}
          >
            Export
          </button>
        </div>
        <Filter
          date={date}
          data={data}
          setDate={setDate}
          setData={setData}
          axiosUrl={axiosUrl}
          res_Array={res_Array}
        />
      </div>
    </div>
  );
};

export default MiniHeader;
