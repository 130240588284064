import { useState, useEffect } from "react";

function usePagination(users, setCurrentUsers, userPerPage, setIndexing) {
  const [currentPage, setCurrentPage] = useState(1);
  const lastIndex = currentPage * userPerPage;
  const firstIndex = lastIndex - userPerPage;
  const nPage = users && Math.ceil(users.length / userPerPage);
  const shortedProducts =
    users &&
    users.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
  const slicedProducts = shortedProducts.slice(firstIndex, lastIndex);
  const pageNumbers = users && [...Array(nPage + 1).keys()].slice(1);
  const adjustIndex = (index) => {
    setCurrentPage(index);
    setIndexing(index);
  };
  useEffect(() => {
    if (users) {
      setCurrentUsers(slicedProducts);
    }
  }, [users, currentPage]);
  const setPage = (newPage) => {
    if (newPage >= 1 && newPage <= nPage) {
      adjustIndex(newPage);
    }
  };
  const nextPage = () => {
    if (currentPage !== lastIndex) {
      setPage(currentPage + 1);
    }
  };
  const previousPage = () => {
    if (currentPage !== firstIndex && currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  return {
    pageNumbers,
    slicedProducts,
    nextPage,
    previousPage,
    currentPage,
    userPerPage,
  };
}

export default usePagination;
