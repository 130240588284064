import * as XLSX from "xlsx";
import { toast } from "react-toastify";

const ExportDataHandler = (cleanedLeads) => {
  try {
    const jsonData = JSON.stringify(cleanedLeads, null, 2);
    const worksheet = XLSX.utils.json_to_sheet(JSON.parse(jsonData));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");
    XLSX.writeFile(workbook, "leads_data.xlsx");
    toast("Data Exported Successfully");
  } catch (error) {
    console.error("Error:", error);
  }
};

export default ExportDataHandler;
