import React, { createContext, useState } from "react";

export const AppContext = createContext();

const Context = ({ children }) => {
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <AppContext.Provider value={{ openMenu, setOpenMenu }}>
      {children}
    </AppContext.Provider>
  );
};

export default Context;
