import Login from "./pages/login";
import image1 from "./assets/pexels-nickoloui-2473183.jpg";
import MappleRoutes from "./routes";
const App = () => {
  return (
    <div className="  w-screen h-screen relative ">
     
  <MappleRoutes/>
     
    </div>
  );
};

export default App;
