import PrivateRoute from "../config/privateRoute";

const MappleRoutes = () => {
  return (
    <>
      <PrivateRoute />
    </>
  );
};

export default MappleRoutes;
