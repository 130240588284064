import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Cookies from "js-cookie";
import { formatDate, formatTime } from "../hooks/formatDateTime";
import { FiDownload } from "react-icons/fi";
import Loader from "../components/loader";
import axios from "axios";
import MiniHeader from "../components/miniHeader";
import ExportDataHandler from "../components/ExportDataHandler";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

const AbandonedCheckout = () => {
  const [data, setData] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [indexing, setIndexing] = useState(1);
  const userPerPage = 10;
  const dateData = {
    from: "",
    till: "",
  };
  const [filterdate, setDate] = useState(dateData);

  const token = Cookies.get("token");

  const ExportPayload = (data) => {};
  // const ExportPayload = (data) => {
  //   const payload = Array.isArray(data) ? data : [data];
  //   const cleanedLeads = payload.map(
  //     ({
  //       id,
  //       abandoned: {
  //         customer: {
  //           email,
  //           firstname,
  //           lastname,
  //           phone,
  //           address: { zip, city, country, address1, address2 },
  //         },
  //         products,
  //         createdAt,
  //         checkoutUrl,
  //       },
  //       createdAt: abandonedCreatedAt,
  //       updatedAt: abandonedUpdatedAt,
  //       ...rest
  //     }) => ({
  //       id,
  //       Email: email,
  //       Name: `${firstname} ${lastname}`,
  //       Mobile_Number: phone,
  //       Address: `${address1}, ${address2}, ${city}, ${country}, ${zip}`,
  //       Products: products.map(({ product_id, variant_id, productTitle, sku, quantity }) => ({
  //         Product_ID: product_id,
  //         Variant_ID: variant_id,
  //         Product_Title: productTitle,
  //         SKU: sku,
  //         Quantity: quantity,
  //       })),

  //       Checkout_URL: checkoutUrl,
  //       Created_At: createdAt,
  //       Abandoned_Created_At: abandonedCreatedAt,
  //       Abandoned_Updated_At: abandonedUpdatedAt,
  //       ...rest,
  //     })
  //   );

  //     return cleanedLeads;
  //   };

  const abc = [
    {
      id: 1,
      abandoned: {
        cartUrl: "",
        customer: {
          email: "outreachme12@gmail.com",
          phone: "",
          address: {
            zip: "560073",
            city: "Bangalore",
            country: "India",
            address1: "No 11 maruthi nilaya chikkabidarakallu",
            address2: "Cn palya chikkabidarakallu",
          },
          lastname: "K",
          firstname: "Shamanth",
        },
        products: [
          {
            id: "gid://shopify/AbandonedCheckoutLineItem/e359286216fe24c9d512e26a26b67dab?checkout=d108c49ed931e02ed0c0e053379211b9&index=0",
            sku: "MTP43HN/A",
            quantity: "1",
            product_id: "gid://shopify/Product/8618187882786",
            variant_id: "gid://shopify/ProductVariant/46697625026850",
            productTitle: "iPhone 15 128GB Blue",
          },
          {
            id: "gid://shopify/AbandonedCheckoutLineItem/f359286216fe24c9d512e26a26b67dac?checkout=d208c49ed931e02ed0c0e053379211b0&index=1",
            sku: "MWP52ZM/A",
            quantity: "2",
            product_id: "gid://shopify/Product/8618187882787",
            variant_id: "gid://shopify/ProductVariant/46697625026851",
            productTitle: "AirPods Pro",
          },
          {
            id: "gid://shopify/AbandonedCheckoutLineItem/g359286216fe24c9d512e26a26b67dae?checkout=d308c49ed931e02ed0c0e053379211b1&index=2",
            sku: "MY482HN/A",
            quantity: "1",
            product_id: "gid://shopify/Product/8618187882788",
            variant_id: "gid://shopify/ProductVariant/46697625026852",
            productTitle: "MacBook Air 13-inch",
          },
        ],
        createdAt: "2024-05-23T06:21:26Z",
        checkoutUrl:
          "https://www.maplestore.in/74086383906/checkouts/d108c49ed931e02ed0c0e053379211b9/recover?key=d47942abacdf7baf712ee4e83528dd5a",
      },
      createdAt: "2024-05-23T06:32:07.000Z",
      updatedAt: "2024-05-23T06:32:07.000Z",
    },
    // Add more carts with different products if needed
  ];

  const processLeadsData = (data) => {
    const flattenedData = [];
    const merges = [];

    data.forEach((cart, index) => {
      const { id, abandoned, createdAt, updatedAt } = cart;
      const { customer, products, checkoutUrl } = abandoned;
      const { email, phone, address, lastname, firstname } = customer;
      const { zip, city, country, address1, address2 } = address;
      const startRow = flattenedData.length + 1; // 1-based index, considering header row

      products.forEach((product) => {
        flattenedData.push({
          CartID: index + 1,
          CustomerFirstName: firstname,
          CustomerLastName: lastname,
          CustomerEmail: email,
          CustomerPhone: phone,
          CartCreatedAt: createdAt,
          CartUpdatedAt: updatedAt,
          AddressZip: zip,
          AddressCity: city,
          AddressCountry: country,
          Address1: address1,
          Address2: address2,
          CheckoutURL: checkoutUrl,
          id: product.id,
          ProductID: product.product_id,
          SKU: product.sku,
          Quantity: product.quantity,
          ProductTitle: product.productTitle,
        });
      });

      const endRow = flattenedData.length;
      // Add merges for common fields across the product rows
      if (products.length > 1) {
        merges.push({ s: { r: startRow, c: 0 }, e: { r: endRow, c: 0 } }); // CartID
        merges.push({ s: { r: startRow, c: 1 }, e: { r: endRow, c: 1 } }); // CartCreatedAt
        merges.push({ s: { r: startRow, c: 2 }, e: { r: endRow, c: 2 } }); // CartUpdatedAt
        merges.push({ s: { r: startRow, c: 3 }, e: { r: endRow, c: 3 } }); // CustomerEmail
        merges.push({ s: { r: startRow, c: 4 }, e: { r: endRow, c: 4 } }); // CustomerPhone
        merges.push({ s: { r: startRow, c: 5 }, e: { r: endRow, c: 5 } }); // AddressZip
        merges.push({ s: { r: startRow, c: 6 }, e: { r: endRow, c: 6 } }); // AddressCity
        merges.push({ s: { r: startRow, c: 7 }, e: { r: endRow, c: 7 } }); // AddressCountry
        merges.push({ s: { r: startRow, c: 8 }, e: { r: endRow, c: 8 } }); // Address1
        merges.push({ s: { r: startRow, c: 9 }, e: { r: endRow, c: 9 } }); // Address2
        merges.push({ s: { r: startRow, c: 10 }, e: { r: endRow, c: 10 } }); // CustomerLastName
        merges.push({ s: { r: startRow, c: 11 }, e: { r: endRow, c: 11 } }); // CustomerFirstName
        merges.push({ s: { r: startRow, c: 12 }, e: { r: endRow, c: 12 } }); // CheckoutURL
      }
    });

    return { flattenedData, merges };
  };

  const exportToExcel = (data) => {
    const paylaod = Array.isArray(data) ? data : [data];

    try {
      const { flattenedData, merges } = processLeadsData(paylaod);
      const worksheet = XLSX.utils.json_to_sheet(flattenedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Leads");

      worksheet["!merges"] = merges;

      XLSX.writeFile(workbook, "leads_data.xlsx");
      toast("Data Exported Successfully");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  function fetchData() {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://servicesapi.maplestore.in/user/getabcart",
        headers: {
          Authorization: token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.success === true) {
            setData(response.data.abandoneds);
            // console.log(response.data.abandoneds);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  console.log(filterdate)


  return (
    <Layout>
      <MiniHeader
        title="abandoned checkout"
        onExport={() => exportToExcel(data)}
        date={filterdate}
        data={data}
        setDate={setDate}
        setData={setData}
        axiosUrl="https://servicesapi.maplestore.in/user/filterabcart"
        res_Array="abandoneds"
      />

      <div className="w-full overflow-scroll text-xs md:text-base pb-20 mt-3">
        <table className="text-left table-auto w-full border-separate border-spacing-y-1 scroll-smooth ">
          <thead className="bg-orange-100 text-black  sticky top-0 ">
            <tr className="uppercase">
              {/*  */}
              <th>Sr.</th>
              <th>name/email</th>
              <th>mobile no.</th>

              <th>City</th>

              {/*  */}
              <th>date</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody className="">
            {currentUsers && currentUsers.length > 0 ? (
              currentUsers.map((item, index) => {
                // console.log("item", item);
                // console.log("===>", item.abandoned.products.sku);
                // console.log("===>", formatDate(item.abandoned.createdAt))
                return (
                  <tr
                    key={index}
                    className="odd:bg-white even:bg-gray-50 border-b "
                  >
                    <td>{index + 1 + (indexing - 1) * userPerPage}</td>
                    {/* <td>
                        {item?.repairData?.customerinfo?.CustomerFirstName}
                      </td>
                      <td>{item?.repairData?.customerinfo?.CustomerEmailId}</td> */}

                    <td className="">
                      <td className="flex flex-col p-0">
                        <td className="font-semibold p-0">
                          {item?.abandoned.customer.firstname}
                          {item?.abandoned.customer.lastname}
                        </td>
                        <td className="text-gray-400 p-0">
                          {item?.abandoned.customer.email}
                        </td>
                      </td>
                    </td>

                    <td>{item?.abandoned.customer.phone}</td>

                    <td>{item?.abandoned.customer.address.city}</td>

                    <td>
                      <td className="flex flex-col p-0">
                        <td className="p-0">{formatDate(item.abandoned.createdAt)}</td>
                        <td className="text-gray-400 p-0">
                          {formatTime(item.abandoned.createdAt)}
                        </td>
                      </td>
                    </td>

                    <td
                      className="text-orange-500 text-xl cursor-pointer text-center"
                      // onClick={() => ExportDataHandler(ExportPayload(item))}
                      onClick={() => exportToExcel(item)}
                    >
                      <FiDownload />
                    </td>
                  </tr>
                );
              })
            ) : (
              <Loader className="absolute w-full flex justify-center items-center h-1/2" />
            )}
          </tbody>
        </table>
        <div className="py-10 flex justify-center">
          <Pagination
            users={data}
            userPerPage={userPerPage}
            setCurrentUsers={setCurrentUsers}
            setIndexing={setIndexing}
            indexing={indexing}
            currentUsers={currentUsers}
          />
        </div>
      </div>
    </Layout>
  );
};

export default AbandonedCheckout;
