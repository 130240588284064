import React, { useEffect } from "react";
import usePagination from "../hooks/usePagination";

const Pagination = ({
  users,
  setCurrentUsers,
  userPerPage,
  setIndexing,
  indexing,
  currentUsers,
}) => {
  const { currentPage, nextPage, previousPage, pageNumbers } = usePagination(
    users,
    setCurrentUsers,
    userPerPage,
    setIndexing
  );
  useEffect(() => {
    setIndexing(indexing);
  }, []);

  return (
    <div className="sm:w-1/2 flex w-full justify-around items-center">
      {currentUsers && currentUsers.length > 0 && (
        <>
          <button
            onClick={previousPage}
            className={`${
              currentPage === 1
                ? "border-2 border-orange-500 cursor-default  text-orange-500"
                : " bg-orange-400 text-white"
            }  font-semibold md:text-lg px-7 py-1 rounded-lg  `}
            // disabled={currentPage === 1}
          >
            Prev
          </button>
          <div className="text-orange-500 md:text-lg font-semibold">
            {currentPage}/{pageNumbers.length}
          </div>

          <button
            onClick={nextPage}
            className={`${
              currentPage === pageNumbers.length
                ? "border-2 border-orange-500 cursor-default  text-orange-500"
                : " bg-orange-400 text-white"
            }   font-semibold md:text-lg px-7 py-1 rounded-lg  `}
            disabled={currentPage === pageNumbers.length}
          >
            Next
          </button>
        </>
      )}
    </div>
  );
};

export default Pagination;
