import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/login";
import Dashboard from "../pages/dashboard";
import Cookies from "js-cookie";
import axios from "axios";
import Loader from "../components/loader";
import ByPack from "../pages/byPack";
import WooCommerce from "../pages/wooCommerce";
import Services from "../pages/services";
import Notfound from "../pages/notFound";
import AbandonedCheckout from "../pages/abandonedCheckout";

const PrivateRoute = () => {
  const token = Cookies.get("token");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isTokenExpire, setIsTokenExpire] = useState(false);
  const verifyUser = (token) => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://servicesapi.maplestore.in/user/verifyuser",
      headers: {
        Authorization: token,
      },
    };

    axios
      .request(config)
      .then((response) => {
        // console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setIsAuthenticated(true);
          setIsTokenExpire(false);
        } else {
          Cookies.remove("token");
          setIsAuthenticated(false);
          setIsTokenExpire(true);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setIsAuthenticated(false);
        setIsTokenExpire(true);
        Cookies.remove("token");
      });
  };

  useEffect(() => {
    if (!token) {
      setIsAuthenticated(false);
    } else {
      verifyUser(token);
      // console.warn("t====>", token);
    }
  }, [token]);

  // console.warn("isAuthenticated", isAuthenticated);

  useEffect(() => {
    if (token === undefined || token === null) {
      setIsTokenExpire(true);
    } else {
      setIsTokenExpire(false);
    }
  }, [isAuthenticated, token]);

  // console.log("isAuthenticated: ", isAuthenticated);

  return (
    <Routes>
      {isAuthenticated === false ? (
        <Route
          path="*"
          element={
            isTokenExpire ? (
              <Login />
            ) : (
              <Loader className="h-screen items-center flex justify-center" />
            )
          }
        />
      ) : (
        <>
          <Route path="/" element={<Dashboard />} />
          <Route path="/services" element={<Services />} />
          <Route path="/buypack" element={<ByPack />} />
          <Route path="/woocommerce" element={<WooCommerce />} />
          <Route path="/checkout" element={<AbandonedCheckout />} />
          <Route path="*" element={<Notfound />} />
        </>
      )}
    </Routes>
  );
};

export default PrivateRoute;
