import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Cookies from "js-cookie";
import { formatDate, formatTime } from "../hooks/formatDateTime";
import { FiDownload } from "react-icons/fi";
import Loader from "../components/loader";
import axios from "axios";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MiniHeader from "../components/miniHeader";
import ExportDataHandler from "../components/ExportDataHandler";

const WooCommerce = () => {
  const [data, setData] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [indexing, setIndexing] = useState(1);
  const userPerPage = 10;
  const dateData = {
    from: "",
    till: "",
  };
  const [filterdate, setDate] = useState(dateData);
  const token = Cookies.get("token");
  const ExportPayload = (data) => {
    const paylaod = Array.isArray(data) ? data : [data];
    const cleanedLeads =
      paylaod.length > 0 &&
      paylaod.map(
        ({
          id,
          wpdata: {
            name,
            email,
            model,
            phone,
            source,
            stores,
            storage,
            market_value,
            buyback_value,
            model_condition,
            planning_to_buy,
          },
          createdAt,
          updatedAt,
          ...rest
        }) => ({
          id,
          name,
          email,
          model,
          phone,
          source,
          stores,
          storage,
          market_value,
          buyback_value,
          model_condition,
          planning_to_buy,
          createdAt,
          updatedAt,

          ...rest,
        })
      );
    return cleanedLeads;
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  function fetchData() {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://servicesapi.maplestore.in/user/getwps",
        headers: {
          Authorization: token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data.repairs));
          if (response.data.success === true) {
            setData(response.data.buyPack);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <ToastContainer position="top-center" />

      <MiniHeader
        title="Woo commerce"
        onExport={() => ExportDataHandler(ExportPayload(data))}
        date={filterdate}
        data={data}
        setDate={setDate}
        setData={setData}
        axiosUrl="https://servicesapi.maplestore.in/user/filterwps"
        res_Array="repairs"
      />

      <div className="w-full overflow-scroll text-xs md:text-base pb-20 mt-3">
        <table className="text-left table-auto w-full border-separate border-spacing-y-1 scroll-smooth ">
          <thead className="bg-orange-100 text-black  sticky top-0 ">
            <tr className="uppercase">
              {/*  */}
              <th>Sr.</th>
              <th>name/email</th>
              <th>mobile no.</th>
              <th>Source</th>
              <th>model</th>
              <th>stores</th>
              <th>Condition </th>
              <th>planning_to_buy</th>
              {/*  */}
              <th>date</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody className="">
            {currentUsers && currentUsers.length > 0 ? (
              currentUsers.map((item, index) => {
                // console.log("item", item);
                return (
                  <tr
                    key={index}
                    className="odd:bg-white even:bg-gray-50 border-b "
                  >
                    <td>{index + 1 + (indexing - 1) * userPerPage}</td>
                    {/* <td>
                        {item?.repairData?.customerinfo?.CustomerFirstName}
                      </td>
                      <td>{item?.repairData?.customerinfo?.CustomerEmailId}</td> */}

                    <td className="">
                      <td className="flex flex-col p-0">
                        <td className="font-semibold p-0">
                          {item?.wpdata?.name}
                        </td>
                        <td className="text-gray-400 p-0">
                          {item?.wpdata?.email}
                        </td>
                      </td>
                    </td>

                    <td>{item?.wpdata?.phone}</td>
                    <td>{item?.wpdata?.source}</td>
                    <td className="flex flex-col">
                      {item?.wpdata?.model}{" "}
                      <span className="text-gray-500">
                        {" "}
                        storage :{" "}
                        <span className="text-orange-400 font-semibold">
                          {item?.wpdata?.storage}{" "}
                        </span>
                      </span>
                    </td>
                    <td>{item?.wpdata?.stores}</td>
                    <td>{item?.wpdata?.model_condition}</td>
                    <td>{item?.wpdata?.planning_to_buy}</td>

                    <td>
                      <td className="flex flex-col p-0">
                        <td className="p-0">{formatDate(item.createdAt)}</td>
                        <td className="text-gray-400 p-0">
                          {formatTime(item.createdAt)}
                        </td>
                      </td>
                    </td>

                    <td
                      className="text-orange-500 text-xl cursor-pointer text-center"
                      onClick={() => ExportDataHandler(ExportPayload(item))}
                    >
                      <FiDownload />
                    </td>
                  </tr>
                );
              })
            ) : (
              <Loader className="absolute w-full flex justify-center items-center h-1/2" />
            )}
          </tbody>
        </table>
        <div className="py-10 flex justify-center">
          <Pagination
            users={data}
            userPerPage={userPerPage}
            setCurrentUsers={setCurrentUsers}
            setIndexing={setIndexing}
            indexing={indexing}
            currentUsers={currentUsers}
          />
        </div>
      </div>
    </Layout>
  );
};

export default WooCommerce;
