import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Pagination from "../components/pagination";
import Cookies from "js-cookie";
import { formatDate, formatTime } from "../hooks/formatDateTime";
import { FiDownload } from "react-icons/fi";
import Loader from "../components/loader";
import axios from "axios";
import MiniHeader from "../components/miniHeader";
import ExportDataHandler from "../components/ExportDataHandler";

const ByPack = () => {
  const [data, setData] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);

  const [indexing, setIndexing] = useState(1);
  const userPerPage = 10;
  const dateData = {
    from: "",
    till: "",
  };
  const [filterdate, setDate] = useState(dateData);

  const token = Cookies.get("token");

  const ExportPayload = (data) => {
    const paylaod = Array.isArray(data) ? data : [data];
    const cleanedLeads =
      paylaod.length > 0 &&
      paylaod.map(
        ({
          id,
          buypack: {
            Name,
            Email,
            Source,
            Stores,
            Storage,
            Variant,
            Condition,
            Processor,
            Device_Name,
            Maple_Price,
            Screen_Size,
            Buyback_Type,
            Market_Price,
            Mobile_Number,
            Maple_Total_Price,
            How_soon_are_you_planning_to_buy_the_device,
          },
          createdAt,
          updatedAt,
          ...rest
        }) => ({
          id,
          Name,
          Email,
          Source,
          Stores,
          Storage,
          Variant,
          Condition,
          Processor,
          Device_Name,
          Maple_Price,
          Screen_Size,
          Buyback_Type,
          Market_Price,
          Mobile_Number,
          Maple_Total_Price,
          How_soon_are_you_planning_to_buy_the_device,
          createdAt,
          updatedAt,

          ...rest,
        })
      );
    return cleanedLeads;
  };

  useEffect(() => {
    fetchData();
  }, [token]);

  function fetchData() {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://servicesapi.maplestore.in/user/getbuypacks",
        headers: {
          Authorization: token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data.success === true) {
            setData(response.data.buyPack);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Layout>
      <MiniHeader
        title="By-Pack"
        onExport={() => ExportDataHandler(ExportPayload(data))}
        date={filterdate}
        data={data}
        setDate={setDate}
        setData={setData}
        axiosUrl="https://servicesapi.maplestore.in/user/filterbuypacks"
        res_Array="repairs"
      />

      <div className="w-full overflow-scroll text-xs md:text-base pb-20 mt-3">
        <table className="text-left table-auto w-full border-separate border-spacing-y-1 scroll-smooth ">
          <thead className="bg-orange-100 text-black  sticky top-0 ">
            <tr className="uppercase">
              {/*  */}
              <th>Sr.</th>
              <th>name/email</th>
              <th>mobile no.</th>
              <th>Source</th>
              <th>Stores</th>
              <th>Device</th>
              <th>Condition </th>
              <th>planning to buy</th>
              {/*  */}
              <th>date</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody className="">
            {currentUsers && currentUsers.length > 0 ? (
              currentUsers.map((item, index) => {
                // console.log("item", item);
                return (
                  <tr
                    key={index}
                    className="odd:bg-white even:bg-gray-50 border-b "
                  >
                    <td>{index + 1 + (indexing - 1) * userPerPage}</td>
                    {/* <td>
                        {item?.repairData?.customerinfo?.CustomerFirstName}
                      </td>
                      <td>{item?.repairData?.customerinfo?.CustomerEmailId}</td> */}

                    <td className="">
                      <td className="flex flex-col p-0">
                        <td className="font-semibold p-0">
                          {item?.buypack.Name}
                        </td>
                        <td className="text-gray-400 p-0">
                          {item?.buypack.Email}
                        </td>
                      </td>
                    </td>

                    <td>{item?.buypack.Mobile_Number}</td>
                    <td
                      className={`${
                        item?.buypack.Source === "Online"
                          ? "text-green-500"
                          : "text-orange-500"
                      }   `}
                    >
                      {item?.buypack.Source}
                    </td>
                    <td>{item?.buypack.Stores}</td>
                    <td className="flex flex-col">
                      {item?.buypack?.Device_Name}{" "}
                      <span className="text-gray-500">
                        {" "}
                        storage :{" "}
                        <span className="text-orange-400 font-semibold">
                          {item?.buypack?.Variant}{" "}
                        </span>
                      </span>
                    </td>
                    {/* 
                    <td>{item?.buypack.Variant}</td>
                    <td>{item?.buypack?.Device_Name}</td> */}
                    <td>{item?.buypack?.Condition}</td>
                    <td>
                      {
                        item?.buypack
                          ?.How_soon_are_you_planning_to_buy_the_device
                      }
                    </td>

                    <td>
                      <td className="flex flex-col p-0">
                        <td className="p-0">{formatDate(item.createdAt)}</td>
                        <td className="text-gray-400 p-0">
                          {formatTime(item.createdAt)}
                        </td>
                      </td>
                    </td>

                    <td
                      className="text-orange-500 text-xl cursor-pointer text-center"
                      onClick={() => ExportDataHandler(ExportPayload(item))}
                    >
                      <FiDownload />
                    </td>
                  </tr>
                );
              })
            ) : (
              <Loader className="absolute w-full flex justify-center items-center h-1/2" />
            )}
          </tbody>
        </table>
        <div className="py-10 flex justify-center">
          <Pagination
            users={data}
            userPerPage={userPerPage}
            setCurrentUsers={setCurrentUsers}
            setIndexing={setIndexing}
            indexing={indexing}
            currentUsers={currentUsers}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ByPack;
