import React from "react";
import Layout from "../components/layout";
import { useNavigate } from "react-router-dom";

const Notfound = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <section className="page_404 w-full flex justify-center items-center">
        <div className="container">
          <div className="col-sm-10 col-sm-offset-1  text-center ">
            <div className="four_zero_four_bg">
              <h1 className="text-center ">404</h1>
            </div>
            <div className="contant_box_404">
              <h3 className="h2">Look like you're lost</h3>
              <p>the page you are looking for not avaible!</p>
              <button
                href=""
                className="px-5 py-3 bg-orange-200 mt-4 rounded-md text-orange-500 font-semibold"
                onClick={() => navigate("/")}
              >
                Go to Home
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Notfound;
