import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Filter = ({ date, data, setDate, setData, axiosUrl, res_Array }) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const token = Cookies.get("token");
  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: formatDate(value) });
  };
  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
    return formattedDate.split("-").reverse().join("-");
  };
  const formatDate = (date) => {
    return date.split("-").join("-");
  };
  const FilterHandler = () => {
    setLoading(true);
    let data = JSON.stringify({
      startDate: date.from,
      endDate: date.till,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: axiosUrl,
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setData(response.data?.[res_Array]);
          setLoading(false);

          if (response.data?.[res_Array].length === 0) {
            toast("No repair data here, please change date");
          }
        } else {
          toast("data is empty");
          setIsError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsError(true);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
      setIsError(false);
    }, 2000);
  }, [isError]);

  return (
    <div className="border bg-white gap-3 xl:w-1/2 md:w-2/3 w-11/12  rounded-xl py-2 px-3 xl:px-10 flex  max-[375px]:flex-col flex-row justify-between -mt-3 md:-mt-8  xl:-mt-10">
      <div className="flex gap-3 max-[375px]:justify-between">
        <input
          placeholder="from"
          name="from"
          type="date"
          max={getCurrentDate()}
          className="outline-none  border  rounded-lg md:text-base text-xs px-0.5  max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2  sm:w-auto"
          onChange={dateHandler}
          value={formatDate(date.from)}
        />
        <input
          placeholder="till"
          name="till"
          type="date"
          min={formatDate(date.from)}
          max={getCurrentDate()}
          className="outline-none  border rounded-lg  md:text-base text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3  sm:px-2 lg:px-5 py-2 sm:w-auto"
          onChange={
            date.from !== "" && date.till === ""
              ? setDate({ ...date, till: formatDate(getCurrentDate()) })
              : dateHandler
          }
          value={
            date.from !== "" && date.till === ""
              ? getCurrentDate()
              : formatDate(date.till)
          }
        />
      </div>
      <button
        className={`bg-white ${
          loading && "border-none"
        } border border-orange-500 text-orange-500 relative font-medium px-5 max-[375px]:mb-2 lg:px-10 rounded-lg text-base md:text-lg capitalize`}
        onClick={FilterHandler}
        disabled={loading === true || date.from > date.till || date.from === ""}
      >
        {loading ? <div className="FilterLoader"></div> : "filter"}
      </button>
    </div>
  );
};

export default Filter;
