export const formatDate = (dateString) => {
  const date = new Date(dateString);
  return Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  }).format(date);
};

export const formatTime = (dateString) => {
  const date = new Date(dateString);
  return Intl.DateTimeFormat("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).format(date);
};
