import React, { useState } from "react";
import Logo from "../components/logo";
import axios from "axios";
import Cookies from "js-cookie";

const Login = () => {
  const initalData = {
    email: "",
    password: "",
  };
  const [loginData, setLoginData] = useState(initalData);
  const InputHandler = (e) => {
    const { name, value } = e.target;
    setLoginData({ ...loginData, [name]: value });
  };

  const SignInHandler = (e) => {
    e.preventDefault();
    try {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://servicesapi.maplestore.in/user/login",
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify(loginData),
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data.user));
          if (response.data.success === true) {
            const token = response.data.user.token;
            Cookies.set("token", token, { expires: 2, secure: true });
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="  flex justify-center items-center w-full h-full bg-gradient-to-tr  relative  overflow-hidden">
      <div className="backdrop-blur shadow-xl bg-gradient-to-b from-white/20  to-transparent to-80%  border-white/10  rounded-lg px-20 md:px-10 py-10 flex flex-col justify-center items-center gap-10  md:w-1/2  lg:w-1/2 xl:w-1/4">
        <Logo color1="black" />
        <form
          className="flex flex-col gap-4 items-center w-full mt-5"
          onSubmit={SignInHandler}
        >
          <input
            placeholder="Email"
            name="email"
            className="outline-none  border px-3 py-2  sm:text-xl rounded-md w-full "
            value={loginData.email}
            onChange={InputHandler}
            required
            type="email"
          />
          <input
            placeholder="Password"
            name="password"
            value={loginData.password}
            className="outline-none  border px-3 py-2 sm:text-xl  rounded-md w-full  "
            onChange={InputHandler}
            type="password"
          />
          <button
            className={`py-3 text-white bg-orange-500 px-10 rounded-lg font-semibold mt-5`}
            type="submit"
          >
            Sign in
          </button>
        </form>
      </div>
      <div className="main-container absolute top-20 right-0"></div>
    </div>
  );
};

export default Login;
