import React from "react";
import Layout from "../components/layout";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <div className="flex md:flex-row flex-col justify-center items-center  h-[calc(100%-100px)] text-lg  md:text-[1.5rem] lg:text-[3rem] font-bold text-center">
        <div
          className="w-full hover:bg-orange-100  border-orange-500  hover:text-orange-400 cursor-pointer transition duration-500 ease-in-out h-full flex items-center justify-center"
          onClick={() => navigate("/services")}
        >
          <h1>Services</h1>
        </div>
        <div
          className="w-full border-y bg-orange-100 hover:bg-orange-100 md:border-x md:border-y-0 hover:border-orange-300 border-orange-500  hover:text-orange-400 cursor-pointer transition duration-500 ease-in-out h-full flex items-center justify-center"
          onClick={() => navigate("/buypack")}
        >
          <h1>Buy Pack</h1>
        </div>
        <div
          className="w-full leading-[3rem] hover:bg-orange-100  border-orange-500   hover:text-orange-400 cursor-pointer transition duration-500 ease-in-out h-full flex items-center justify-center"
          onClick={() => navigate("/woocommerce")}
        >
          <h1>Woo Commerce</h1>
        </div>
        <div
          className="w-full leading-[3rem] border-y bg-orange-100 hover:bg-orange-100 md:border-x md:border-y-0 hover:border-orange-300 border-orange-500  hover:text-orange-400 cursor-pointer transition duration-500 ease-in-out h-full flex items-center justify-center"
          onClick={() => navigate("/checkout")}
        >
          <h1>Abandoned Checkout</h1>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
