import axios from "axios";
import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Layout from "../components/layout";
import { FiDownload } from "react-icons/fi";
import * as XLSX from "xlsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExportDataHandler from "../components/ExportDataHandler";
import Loader from "../components/loader";
import Pagination from "../components/pagination";
import Filter from "../components/filter";
import { formatDate, formatTime } from "../hooks/formatDateTime";
import MiniHeader from "../components/miniHeader";

const Services = () => {
  const [data, setData] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [indexing, setIndexing] = useState(1);
  const userPerPage = 10;
  const dateData = {
    from: "",
    till: "",
  };
  const [filterdate, setDate] = useState(dateData);
  const token = Cookies.get("token");
  useEffect(() => {
    fetchData();
  }, [token]);

  function fetchData() {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: "https://servicesapi.maplestore.in/user/repairdatas",
        headers: {
          Authorization: token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data.repairs));
          if (response.data.success === true) {
            setData(response.data.repairs);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  const ExportPayload = (data) => {
    const paylaod = Array.isArray(data) ? data : [data];
    const cleanedLeads =
      paylaod.length > 0 &&
      paylaod.map(
        ({
          id,
          repairData: {
            TimeSlot,
            paymentinfo,
            productinfo: {
              SerialNo,
              issueList,
              ProductFamily,
              WarrantyStatus,
              ProblemOrRemark,
              ProductDescription,
            },
            LocationCode,
            ServiceRefNo,
            customerinfo: {
              CustomerCity,
              CustomerState,
              CustomerCountry,
              CustomerEmailId,
              CustomerPincode,
              CustomerLastName,
              CustomerMobileNo,
              CustomerFirstName,
              CustomerAddressLine1,
              CustomerAddressLine2,
              CustomerAddressLine3,
              CustomerAddressLandmark,
              CustomerAlternateMobileNo,
            },
            ReservationDate,
            ReservationType,
            ReservationStatus,
          },
          createdAt,
          updatedAt,
          ...rest
        }) => ({
          id,
          TimeSlot,
          Amount: paymentinfo.Amount,
          PaymentMode: paymentinfo.PaymentMode,
          PaymentTransactionNo: paymentinfo.PaymentTransactionNo,
          SerialNo,
          issueList,
          ProductFamily,
          WarrantyStatus,
          ProblemOrRemark,
          ProductDescription,
          LocationCode,
          ServiceRefNo,
          CustomerCity,
          CustomerState,
          CustomerCountry,
          CustomerEmailId,
          CustomerPincode,
          CustomerLastName,
          CustomerMobileNo,
          CustomerFirstName,
          CustomerAddressLine1,
          CustomerAddressLine2,
          CustomerAddressLine3,
          CustomerAddressLandmark,
          CustomerAlternateMobileNo,
          ReservationDate,
          ReservationType,
          ReservationStatus,
          createdAt,
          updatedAt,
          ...rest,
        })
      );
    return cleanedLeads;
  };

  useEffect(() => {
    if (filterdate.till) {
      if (filterdate.from > filterdate.till) {
        toast("Wrong date input");
      }
    }
  }, [filterdate]);

  return (
    <Layout>
      <ToastContainer position="top-center" />
      <div className="w-full text-center mt-5 flex flex-col items-center gap-2 select-none">
        <MiniHeader
          title="Services"
          onExport={() => ExportDataHandler(ExportPayload(data))}
          date={filterdate}
          data={data}
          setDate={setDate}
          setData={setData}
          axiosUrl="https://servicesapi.maplestore.in/user/filterdata"
          res_Array="repairs"
        />

        <div className="w-full overflow-scroll text-xs md:text-base pb-20 mt-3">
          <table className="text-left table-auto w-full border-separate border-spacing-y-1 scroll-smooth ">
            <thead className="bg-orange-100 text-black  sticky top-0 ">
              <tr className="uppercase">
                <th>Sr.</th>
                <th>name/email</th>
                <th>mobile no.</th>
                <th>serial no.</th>
                <th>pincode</th>
                <th>product</th>
                <th>family </th>
                <th>date</th>
                <th>Download</th>
              </tr>
            </thead>
            <tbody className="">
              {currentUsers && currentUsers.length > 0 ? (
                currentUsers.map((item, index) => {
                  // console.log("item", item);
                  return (
                    <tr
                      key={index}
                      className="odd:bg-white even:bg-gray-50 border-b "
                    >
                      <td>{index + 1 + (indexing - 1) * userPerPage}</td>
                      {/* <td>
                        {item?.repairData?.customerinfo?.CustomerFirstName}
                      </td>
                      <td>{item?.repairData?.customerinfo?.CustomerEmailId}</td> */}

                      <td className="">
                        <td className="flex flex-col p-0">
                          <td className="font-semibold p-0">
                            {item?.repairData?.customerinfo?.CustomerFirstName}
                          </td>
                          <td className="text-gray-400 p-0">
                            {item?.repairData?.customerinfo?.CustomerEmailId}
                          </td>
                        </td>
                      </td>

                      <td>
                        {item?.repairData?.customerinfo?.CustomerMobileNo}
                      </td>
                      <td>{item?.repairData?.productinfo?.SerialNo}</td>
                      <td>{item?.repairData?.customerinfo?.CustomerPincode}</td>
                      <td>
                        {item?.repairData?.productinfo?.ProductDescription}
                      </td>
                      <td>{item?.repairData?.productinfo?.ProductFamily}</td>

                      <td>
                        <td className="flex flex-col p-0">
                          <td className="p-0">{formatDate(item.createdAt)}</td>
                          <td className="text-gray-400 p-0">
                            {formatTime(item.createdAt)}
                          </td>
                        </td>
                      </td>

                      <td
                        className="text-orange-500 text-xl cursor-pointer text-center"
                        onClick={() => ExportDataHandler(ExportPayload(item))}
                      >
                        <FiDownload />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <Loader className="absolute w-full flex justify-center items-center h-1/2" />
              )}
            </tbody>
          </table>
          <div className="py-10 flex justify-center">
            <Pagination
              users={data}
              userPerPage={userPerPage}
              setCurrentUsers={setCurrentUsers}
              setIndexing={setIndexing}
              indexing={indexing}
              currentUsers={currentUsers}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Services;
