import React from "react";

const Footer = () => {
  return (
    <div className="w-full bg-black text-white flex justify-center items-center text-sm fixed bottom-0 py-2   font-bold tracking-widest ">
      © 2024 Maple | Admin
    </div>
  );
};

export default Footer;
